<app-dialog-header
  title="Notification Settings for {{ userName }}"
  (closeDialog)="dialogRef.close()"
></app-dialog-header>
<mat-dialog-content>
  <div class="flex justify-end">
    <span class="top-email">Email</span>
  </div>
  <mat-list role="list">
    <mat-list-item role="listitem">
      <div class="flex justify-between all-notifications">
        <span>Toggle all notifications</span>
        <mat-slide-toggle
          (change)="changeSelectAll()"
          [ngModel]="masterEmailToggle"
          (ngModelChange)="masterEmailToggle = $event ? 1 : 0"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span>Send me a notification when a task is assigned to me specifically</span>

        @if (userNotificationSettings) {
        <mat-slide-toggle
          [ngModel]="userNotificationSettings.notifyEmailAssignmentUser"
          (ngModelChange)="userNotificationSettings.notifyEmailAssignmentUser = $event ? 1 : 0"
          (change)="commitChange()"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
        }
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span>Send me a notification when a task is assigned to my group/role</span>

        @if (userNotificationSettings) {
        <mat-slide-toggle
          [ngModel]="userNotificationSettings.notifyEmailAssignmentGroup"
          (ngModelChange)="userNotificationSettings.notifyEmailAssignmentGroup = $event ? 1 : 0"
          (change)="commitChange()"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
        }
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span class="notification-title">
          <p class="center-align">Send me automatic task reminders</p>
          <app-tooltip
            [position]="tooltipPosition"
            tooltipText="Automatic task reminders are set by managers and administrators before the task spawns, and the message is generic. If you think a particular task is sending too many or not enough reminders, ask someone with task editing capabilities to adjust the reminder settings for that task."
          ></app-tooltip>
        </span>

        @if (userNotificationSettings) {
        <mat-slide-toggle
          [ngModel]="userNotificationSettings.notifyEmailReminder"
          (ngModelChange)="userNotificationSettings.notifyEmailReminder = $event ? 1 : 0"
          (change)="commitChange()"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
        }
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span class="notification-title">
          <p class="center-align">Send me manual task reminders</p>
          <app-tooltip
            [position]="tooltipPosition"
            tooltipText="Manual reminders are sent purposefully and individually after the task spawns. These cannot be turned off. If you are receiving too many of these notifications, ask the users who are sending them to stop."
          ></app-tooltip>
        </span>
        <mat-slide-toggle
          [disabled]="true"
          [checked]="true"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span class="notification-title">
          <p class="center-align">Send me notifications regarding my account</p>
          <app-tooltip
            [position]="tooltipPosition"
            tooltipText="This includes security alerts, notifications regarding your password or other credentials, and messages from Terlumina employees. These notifications cannot be turned off."
          ></app-tooltip>
        </span>
        <mat-slide-toggle
          [disabled]="true"
          [checked]="true"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
      </div>
    </mat-list-item>
    <mat-list-item role="listitem">
      <div class="flex justify-between sub-notifications">
        <span class="notification-title">
          <p class="center-align">Send me other notifications</p>
          <app-tooltip
            [position]="tooltipPosition"
            tooltipText="This includes notifications which do not belong in any of the above categories."
          ></app-tooltip>
        </span>

        @if (userNotificationSettings) {
        <mat-slide-toggle
          [ngModel]="userNotificationSettings.notifyEmailOther"
          (ngModelChange)="userNotificationSettings.notifyEmailOther = $event ? 1 : 0"
          (change)="commitChange()"
          class="mr-8"
          color="accent"
        ></mat-slide-toggle>
        }
      </div>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<div class="dialog-actions">
  <app-new-action-button
    (actionCompleted)="dialogRef.close()"
    themedButton="secondary"
    text="Close"
  ></app-new-action-button>
</div>
